import React from 'react'

const Email = ({ height = 13, className }) => {
  return (
    <svg height={height} className={className} fill="none" viewBox="0 0 14 11" xmlns="http://www.w3.org/2000/svg">
      <path d="m13.75 1.375c0-0.75625-0.6187-1.375-1.375-1.375h-11c-0.75625 0-1.375 0.61875-1.375 1.375v8.25c0 0.7563 0.61875 1.375 1.375 1.375h11c0.7563 0 1.375-0.6187 1.375-1.375v-8.25zm-2.0724 0c0.201 0 0.2764 0.2631 0.106 0.3696l-4.6436 2.9023c-0.16214 0.10133-0.36786 0.10133-0.53 0l-4.6436-2.9023c-0.1704-0.1065-0.09494-0.3696 0.106-0.3696h9.6052zm0.6974 8.05c0 0.11046-0.0895 0.2-0.2 0.2h-10.6c-0.11046 0-0.2-0.08954-0.2-0.2v-6.0607c0-0.15781 0.17423-0.25346 0.30738-0.16873l4.9242 3.1336c0.16378 0.10422 0.37309 0.10422 0.53688 0l4.9242-3.1336c0.1332-0.08473 0.3074 0.01092 0.3074 0.16873v6.0607z" fill="#073642" />
    </svg>
  )
}

export default Email
