import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby';


const Modal = ({ children, toggle, allImages, index, location }) => {
  return (
    <S.ModalContainer>
      <S.CloseButton
        aria-label="close"
        onClick={() => toggle(false)}
        onMouseDown={() => navigate(`/gallery#${allImages[index].id}`)}
      >
        X
      </S.CloseButton>
      {children}
    </S.ModalContainer>
  )
}


const S = {
  ModalContainer: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(16, 20, 26,.94);
    z-index: 999;
    display: grid;
    align-items: center;
  `,
  CloseButton: styled.button`
    background: none;
    border: 0;
    color: white;
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 2rem;
    height: 2rem;
    font-weight: var(--bolder);
    font-size: 1.3rem;
    z-index: 102;
  `
}

export default Modal
