import React from 'react'
import styled from 'styled-components'
import { animated } from 'react-spring'
import Image from 'gatsby-image'


const CarouselDropletHousing = ({ style, click, fluid, alt }) => {
  return (
    <S.Square style={style} onClick={click}>
      <div>
        <Image fluid={fluid} alt={alt} style={{ width: '100%', height: '100%' }} />
      </div>
    </S.Square>
  )
}

const AnimSquare = animated.div
const AnimCarouselDropletHousing = animated.div


const S = {
  CarouselDropletHousing: styled(AnimCarouselDropletHousing)`
    position: relative;
    right: -3.75vw;
    width: 44%;
    height: calc(100% - (126.4px - 15px));
    top: calc(126.4px - 15px);
    @media (min-width: 1680px) {
      min-height: 800px;
      transition: min-height 0.5s ease;
    }
  `,


  Square: styled(AnimSquare)`
    width: 480px;
    height: 480px;
    top: 50%;
    left: 8vw;
    overflow: hidden;
    border: 15px solid white;
    box-shadow: 40px 55px 40px rgba(0, 0, 0, .5);
    cursor: pointer;
    > div {
      height: 100%;
      width: 100%;
    }
  `
}

export default CarouselDropletHousing
