import React from 'react'
import styled from 'styled-components'

const SWHeatingLogo = ({ height = 49, medHeight = 55, className }) => {
  return (
    <S.SWHeatingLogo className={className} height={height} medHeight={medHeight} viewBox="0 0 196 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M26.4 42.1C27.9 38.4 27 34.6 23.9 31.3C19.4 26.8 17.4 23.2 16.5 20.9C15.6 23.1 13.6 26.8 9.09997 31.3C5.99997 34.5 5.09998 38.5 6.59998 42.1C8.09998 45.6 11.6 48 15.6 48.3C15.9 48.3 16.1 48.3 16.4 48.3C16.7 48.3 16.9 48.3 17.2 48.3C21.4 48 24.9 45.6 26.4 42.1Z" fill="url(#paint0_linear)" />
      <path d="M31.2001 27.5C31.1001 25.2 32.0001 22.3 29.1001 18.8C29.1001 18.8 29.2001 21.1 27.2001 23C27.2001 23 27.9001 19.2 24.8001 14.7C21.6001 10.1 18.8001 8.09999 21.6001 0.799988C21.6001 0.799988 8.10006 7.69999 7.10006 19.6C7.10006 19.6 6.00006 17.9 3.80006 17.4C3.80006 17.4 6.40006 23.1 1.80006 29.6C-1.79994 34.7 1.30006 42.4 8.10006 45.8C3.70006 41.9 2.60006 34.9 8.10006 29.3C16.2001 21 16.5001 15.7 16.5001 15.7C16.5001 15.7 16.9001 21 24.9001 29.3C30.1001 34.7 29.4001 41.3 25.3001 45.3C30.0001 43 32.4001 39.1 32.6001 35.8C32.9001 32.5 31.3001 29.8 31.2001 27.5Z" fill="url(#paint1_linear)" />
      <path d="M109.9 23.7H114.8V28.9H118.6V15.4H114.8V20.3H109.9V15.4H106.1V28.9H109.9V23.7Z" fill="black" />
      <path d="M139.3 15.4H135L130.8 26.3V25.5H123.8V23.7H130.2V20.3H123.8V18.8H130.8V15.4H120V28.9H129.8H130.8H134.1L134.8 27.1H139.5L140.2 28.9H144.5L139.3 15.4ZM135.9 24.3L137.2 21L138.5 24.3H135.9Z" fill="black" />
      <path d="M146.3 28.9H150.2V18.8H153.2V28.9H157V15.4H154.1H153.2H142.4V18.8H146.3V28.9Z" fill="black" />
      <path d="M171.3 15.4H167.5V22.6L162.2 15.4H158.4V28.9H162.2V21.7L167.5 28.9H171.3V15.4Z" fill="black" />
      <path d="M180 29.1C182.1 29.1 184.2 28.2 185.6 26.7V21H179.3V23.8H181.9V25.1C181.3 25.5 180.7 25.6 180 25.6C178.1 25.6 176.5 24 176.5 22.1C176.5 20.2 178.1 18.6 180 18.6C181.1 18.6 182.1 19.1 182.8 20L185.6 17.5C184.2 16 182.1 15.1 180 15.1C176 15.1 172.6 18.3 172.6 22.1C172.6 25.9 176 29.1 180 29.1Z" fill="black" />
      <path d="M111.6 38.7L110.5 38.2C110 39.4 109.4 40.5 108.8 41.3C107.5 39.9 106.5 38.8 105.5 37.7C107.4 36.9 108.9 36.1 108.9 34.3C108.9 32.6 107.5 31.2 105.8 31.2C103.9 31.2 102.5 32.6 102.5 34.4C102.5 35.4 102.9 36.2 103.7 37.4C101.9 38.2 100.5 39.4 100.5 41.3C100.5 43.6 102.4 45.1 104.8 45.1C106.4 45.1 107.7 44.4 108.9 43.1L110.8 45L112 44.5C111.2 43.7 110.4 42.9 109.7 42.2C110.4 41.2 111 40 111.6 38.7ZM103.8 34.4C103.8 33.2 104.7 32.4 105.9 32.4C107 32.4 107.8 33.3 107.8 34.4C107.8 35.6 106.9 36.2 105 37C104.1 35.9 103.8 35.1 103.8 34.4ZM104.9 44C103.3 44 101.8 42.9 101.8 41.3C101.8 39.9 103 38.9 104.4 38.3C105.4 39.5 106.4 40.7 108.1 42.4C107.2 43.4 106.1 44 104.9 44Z" fill="black" />
      <path d="M118.7 36.5C116.8 36.1 116.3 35.9 116.3 35.2C116.3 34.7 116.8 34.3 117.7 34.3C118.9 34.3 120.3 34.8 121.5 35.7L123.4 33C121.9 31.8 120.1 31.2 117.7 31.2C114.5 31.2 112.4 32.9 112.4 35.6C112.4 38.4 114.7 39.2 117.6 39.8C119.5 40.2 119.9 40.3 119.9 41C119.9 41.6 119.4 41.9 118.3 41.9C116.7 41.9 115.2 41.3 113.8 40.3L111.7 42.8C113.4 44.3 115.7 45.1 118.1 45.1C121.5 45.1 123.7 43.4 123.7 40.6C123.8 38 121.8 37.2 118.7 36.5Z" fill="black" />
      <path d="M131.9 31.2C127.9 31.2 124.6 34.4 124.6 38.2C124.6 42 128 45.2 131.9 45.2C135.9 45.2 139.2 42 139.2 38.2C139.2 34.4 135.9 31.2 131.9 31.2ZM131.9 41.7C130.1 41.7 128.5 40.1 128.5 38.2C128.5 36.3 130.1 34.7 131.9 34.7C133.7 34.7 135.3 36.3 135.3 38.2C135.3 40.1 133.8 41.7 131.9 41.7Z" fill="black" />
      <path d="M149.7 38.6L144.4 31.4H140.6V44.9H144.4V37.7L149.7 44.9H153.5V31.4H149.7V38.6Z" fill="black" />
      <path d="M161.699 36.5C159.799 36.1 159.299 35.9 159.299 35.2C159.299 34.7 159.799 34.3 160.699 34.3C161.899 34.3 163.299 34.8 164.499 35.7L166.399 33C164.899 31.8 163.099 31.2 160.699 31.2C157.499 31.2 155.399 32.9 155.399 35.6C155.399 38.4 157.699 39.2 160.599 39.8C162.499 40.2 162.899 40.3 162.899 41C162.899 41.6 162.399 41.9 161.299 41.9C159.699 41.9 158.199 41.3 156.799 40.3L154.699 42.8C156.399 44.3 158.699 45.1 161.099 45.1C164.499 45.1 166.699 43.4 166.699 40.6C166.799 38 164.799 37.2 161.699 36.5Z" fill="black" />
      <path d="M169.2 31.4H167.9V44.9H176.7V43.7H169.2V31.4Z" fill="black" />
      <path d="M188.6 31.4H184.7H184.1H174.1V32.6H178.8V44.9H180.1V32.6H184.1V44.9H188.7C192.9 44.9 195.9 42.1 195.9 38.1C195.9 34.1 192.8 31.4 188.6 31.4ZM188.6 43.7H185.3V32.6H188.6C192.1 32.6 194.5 34.9 194.5 38.2C194.5 41.4 192.1 43.7 188.6 43.7Z" fill="black" />
      <path d="M38.9004 40.4L43.5004 34.9C46.5004 37.3 49.8004 38.5 53.2004 38.5C55.5004 38.5 56.7004 37.7 56.7004 36.4C56.7004 35 55.7004 34.8 51.6004 33.8C45.2004 32.3 40.3004 30.7 40.3004 24.5C40.3004 18.6 44.9004 14.8 51.9004 14.8C57.0004 14.8 61.0004 16.2 64.2004 18.8L60.0004 24.7C57.3004 22.7 54.3004 21.7 51.6004 21.7C49.6004 21.7 48.6004 22.5 48.6004 23.6C48.6004 25.1 49.6004 25.6 53.8004 26.5C60.7004 28 64.9004 29.8 64.9004 35.4C64.9004 41.6 60.1004 45.3 52.8004 45.3C47.6004 45.3 42.6004 43.7 38.9004 40.4Z" fill="url(#paint2_radial)" />
      <path d="M56.7002 15.4H65.7002L69.3002 32.4L75.0002 15.4H84.0002L89.7002 32.4L93.3002 15.4H102.3L94.2002 44.8H85.8002L79.7002 27.8L73.5002 44.8H65.1002L56.7002 15.4Z" fill="url(#paint3_radial)" />
      <defs>
        <linearGradient id="paint0_linear" x1="9.64978" y1="23.6103" x2="23.2811" y2="53.3971" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B2D9F4" />
          <stop offset="0.8714" stopColor="#00ABE7" />
        </linearGradient>
        <linearGradient id="paint1_linear" x1="1.47086" y1="5.09119" x2="31.3236" y2="49.7369" gradientUnits="userSpaceOnUse">
          <stop offset="0.1518" stopColor="#FDC500" />
          <stop offset="0.5277" stopColor="#E30613" />
          <stop offset="1" stopColor="#312A71" />
        </linearGradient>
        <radialGradient id="paint2_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(48.9327 22.4396) scale(47.2208)">
          <stop offset="0.1224" stopColor="#FDC500" />
          <stop offset="0.1472" stopColor="#FAB500" />
          <stop offset="0.2125" stopColor="#F28C00" />
          <stop offset="0.2768" stopColor="#EC6707" />
          <stop offset="0.3385" stopColor="#E84610" />
          <stop offset="0.3972" stopColor="#E52C12" />
          <stop offset="0.4514" stopColor="#E41513" />
          <stop offset="0.497" stopColor="#E30613" />
          <stop offset="0.5574" stopColor="#C3191E" />
          <stop offset="0.6486" stopColor="#952228" />
          <stop offset="0.7361" stopColor="#6F272E" />
          <stop offset="0.8173" stopColor="#522832" />
          <stop offset="0.8907" stopColor="#3D2934" />
          <stop offset="0.9541" stopColor="#302934" />
          <stop offset="1" stopColor="#2A2935" />
        </radialGradient>
        <radialGradient id="paint3_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(56.1053 22.6985) scale(52.415)">
          <stop offset="0.1224" stopColor="#FDC500" />
          <stop offset="0.1472" stopColor="#FAB500" />
          <stop offset="0.2125" stopColor="#F28C00" />
          <stop offset="0.2768" stopColor="#EC6707" />
          <stop offset="0.3385" stopColor="#E84610" />
          <stop offset="0.3972" stopColor="#E52C12" />
          <stop offset="0.4514" stopColor="#E41513" />
          <stop offset="0.497" stopColor="#E30613" />
          <stop offset="0.5501" stopColor="#C9182B" />
          <stop offset="0.6303" stopColor="#A22344" />
          <stop offset="0.7072" stopColor="#7D2956" />
          <stop offset="0.7785" stopColor="#5D2B63" />
          <stop offset="0.8431" stopColor="#462B6B" />
          <stop offset="0.8988" stopColor="#372A70" />
          <stop offset="0.9391" stopColor="#312A71" />
        </radialGradient>
      </defs>
    </S.SWHeatingLogo>
  )
}

const S = {
  SWHeatingLogo: styled.svg`
    @media (min-width: 980px) {
      height: ${({ medHeight }) => `${medHeight}px`}
    }
  `
}

export default SWHeatingLogo
