import React from 'react'
import styled from 'styled-components'

const EnvAgency = ({ height = 43, className }) => {
  return (
    <S.EnvAgency height={height} className={className} viewBox="0 0 172 48" xmlns="http://www.w3.org/2000/svg">
      <path d="m63.95 10.281h0.6664v-3.3316h-11.708v0.76153c1.7134 0.09519 2.1893 0.09518 2.1893 1.7134v8.9478c0 1.9038-0.2855 2.1894-2.1893 2.1894v0.7615h11.804c0.1903-1.1423 0.6663-2.4749 0.9518-3.4268l-0.7615-0.0952c-0.8567 2.0942-1.9038 2.4749-4.0931 2.5701h-1.1423c-2.1893 0-2.1893-0.4759-2.1893-2.6653v-3.2364h2.2845c1.7134 0 2.3797 0.0952 2.3797 1.9038h0.6664l-0.0952-1.3327v-0.9519c0-0.6663 0.0952-1.999 0.0952-2.4749h-0.6664c-0.1903 1.4278-0.2855 1.8086-1.8086 1.8086h-2.8556v-5.3306h2.9508c2.2846-0.19038 3.2365 0.38074 3.522 2.1893z" fill="#00803D" />
      <path d="m66.522 12.565c0.8567 0.1903 1.2375 0.6663 1.2375 1.3326v5.8066c0 0.5711-0.2856 0.5711-0.9519 0.5711h-0.476v0.8567h4.9499v-0.8567h-0.476c-0.6663 0-0.9519 0-0.9519-0.5711v-6.4729c0.7616-0.476 1.8086-0.8567 2.475-0.8567 1.047 0 1.9038 0.3807 1.9038 1.9038v5.5209c0 0.5712-0.2856 0.5712-0.9519 0.5712h-0.5712v0.8567h4.9499v-0.8567h-0.476c-0.6663 0-0.9519 0-0.9519-0.5712v-5.7113c0-2.1894-1.1423-3.2365-2.6653-3.2365-1.3326 0-2.3797 0.6664-3.7124 1.5231v-1.3327c0-0.2855-0.1903-0.2855-0.2855-0.1904-0.7615 0.3808-2.0942 0.8568-2.9509 1.0471v0.6664" fill="#00803D" />
      <path d="m153.72 12.565c0.856 0.1903 1.237 0.6663 1.237 1.3326v5.8066c0 0.5711-0.285 0.5711-0.952 0.5711h-0.476v0.8567h4.95v-0.8567h-0.476c-0.666 0-0.952 0-0.952-0.5711v-6.4729c0.762-0.476 1.809-0.8567 2.475-0.8567 1.047 0 1.904 0.3807 1.904 1.9038v5.5209c0 0.5712-0.285 0.5712-0.952 0.5712h-0.476v0.8567h4.95v-0.8567h-0.476c-0.666 0-0.952 0-0.952-0.5712v-5.7113c0-2.1894-1.142-3.2365-2.665-3.2365-1.333 0-2.38 0.6664-3.712 1.5231v-1.3327c0-0.2855-0.191-0.2855-0.286-0.1904-0.762 0.3808-2.094 0.8568-2.951 1.0471v0.6664" fill="#00803D" />
      <path d="m114.11 12.565c0.857 0.1903 1.238 0.6663 1.238 1.3326v5.8066c0 0.5711-0.286 0.5711-0.952 0.5711h-0.476v0.8567h4.95v-0.8567h-0.476c-0.667 0-0.952 0-0.952-0.5711v-6.4729c0.761-0.476 1.808-0.8567 2.475-0.8567 1.047 0 1.904 0.3807 1.904 1.9038v5.5209c0 0.5712-0.286 0.5712-0.952 0.5712h-0.571v0.8567h4.949v-0.8567h-0.476c-0.666 0-0.951 0-0.951-0.5712v-5.7113c0-2.1894-1.143-3.2365-2.666-3.2365-1.332 0-2.38 0.6664-3.712 1.5231v-1.3327c0-0.2855-0.191-0.2855-0.286-0.1904-0.761 0.3808-2.094 0.8568-2.951 1.0471v0.6664" fill="#00803D" />
      <path d="m88.036 11.994v-0.8567h-3.998v0.7615c0.3808 0 0.6663 0 0.8567 0.0952 0.2856 0.0952 0.3808 0.2856 0.3808 0.5711 0 0.2856-0.1904 0.8567-0.4759 1.523l-1.8087 4.2836-1.9037-4.7595c-0.3808-0.9519-0.3808-1.3326 0-1.523 0.0951-0.0952 0.5711-0.0952 0.8567-0.0952v-0.8567h-5.0451v0.8567c0.8567 0 1.1423 0 1.8086 1.6182l3.2365 7.9007h0.7615l3.4268-7.9959c0.4759-0.9519 0.8567-1.523 1.9038-1.523z" fill="#00803D" />
      <path d="m88.699 11.804v0.4759c0.6663 0.1904 1.1423 0.5711 1.1423 1.4278v5.997c0 0.5711-0.2856 0.5711-0.9519 0.5711h-0.5711v0.8567h4.9498v-0.8567h-0.4759c-0.6664 0-0.9519 0-0.9519-0.5711v-8.4719c0-0.4759-0.1904-0.4759-0.5712-0.2855-0.4759 0.1903-1.7134 0.5711-2.5701 0.8567z" fill="#00803D" />
      <path d="m89.65 7.7106c0 0.66633 0.5711 1.2374 1.2375 1.2374 0.6663 0 1.2374-0.57112 1.2374-1.2374 0-0.66632-0.5711-1.1423-1.2374-1.1423-0.6664 0-1.2375 0.57115-1.2375 1.1423z" fill="#00803D" />
      <path d="m94.6 12.661c0.7616 0.1904 1.1423 0.476 1.1423 1.3327v5.8065c0 0.5712-0.2855 0.5712-0.9519 0.5712h-0.7615v0.8567h5.9967v-0.8567h-1.3324c-0.6664 0-0.9519 0-0.9519-0.5712v-5.1402c0-1.2374 1.1422-1.7134 1.523-1.7134s0.5711 0.0952 0.6663 0.2856c0.19 0.0952 0.381 0.2856 0.762 0.2856 0.666 0 1.142-0.5712 1.142-1.2375 0-0.8567-0.762-1.3327-1.333-1.3327-0.9517 0-2.094 0.8567-2.7603 1.8086v-1.523c0-0.3808-0.2856-0.3807-0.5711-0.1904-0.476 0.2856-1.8086 0.9519-2.5702 1.2375v0.3807z" fill="#00803D" />
      <path d="m102.31 16.183c0 3.3316 2.189 5.2354 5.33 5.2354 2.856 0 5.331-2.0942 5.331-5.3306 0-3.4269-2.19-5.2354-5.331-5.2354-2.951 0-5.33 2.0941-5.33 5.3306zm2.284-0.1904c0-1.999 0.857-4.1884 3.046-4.1884s3.046 2.3798 3.046 4.5691c0 1.999-0.857 4.1884-3.046 4.1884-2.094 0-3.046-2.3798-3.046-4.5691z" fill="#00803D" />
      <path d="m135.53 12.471c-0.381-1.0471-1.142-1.6182-2.38-1.6182-1.523 0-2.475 0.6663-3.712 1.523v-1.1423c0-0.3808-0.286-0.5711-0.762-0.2856-0.095 0.0952-1.332 0.476-2.379 0.7615v0.476c0.666 0.1904 1.142 0.5711 1.142 1.4278v5.997c0 0.5711-0.286 0.5711-0.952 0.5711h-0.571v0.8567h5.045v-0.8567h-0.476c-0.666 0-0.952 0-0.952-0.5711v-6.4729c0.571-0.3808 1.714-1.0471 2.475-1.0471 1.238 0 1.809 0.7615 1.809 2.5701v4.8547c0 0.5711-0.286 0.5711-0.952 0.5711h-0.476v0.8567h4.95v-0.8567h-0.476c-0.667 0-1.047 0-1.047-0.5711v-6.4729c0.571-0.3807 1.713-1.0471 2.475-1.0471 1.237 0 1.808 0.7615 1.808 2.5701v4.8547c0 0.5711-0.285 0.5711-0.952 0.5711h-0.476v0.8567h4.855v-0.8567h-0.476c-0.666 0-0.952 0-0.952-0.5711v-5.6162c0-1.999-1.047-3.2364-2.76-3.2364-1.523 0.2856-2.951 1.2375-3.808 1.9038z" fill="#00803D" />
      <path d="m152.19 15.04 0.285-0.3807c0-2.6653-1.904-3.9028-3.807-3.9028-1.809 0-4.569 1.5231-4.569 5.7114 0 1.7134 0.952 4.9498 4.283 4.9498 1.999 0 3.237-1.3326 4.188-2.5701l-0.476-0.4759c-0.951 0.9519-1.903 1.4278-2.855 1.4278-1.618 0-2.951-1.6182-3.141-3.7124v-0.8567-0.2855h6.092v0.0951zm-5.997-0.9518c0.285-1.4279 1.142-2.3798 2.38-2.3798 1.047 0 1.808 0.7615 1.808 1.9038 0 0.2856-0.19 0.476-0.476 0.476h-3.712z" fill="#00803D" />
      <path d="m87.747 31.509c0.8567 0.1904 1.2375 0.6663 1.2375 1.3327v5.8065c0 0.5712-0.2856 0.5712-0.9519 0.5712h-0.476v0.8567h4.9499v-0.8567h-0.476c-0.6663 0-0.9519 0-0.9519-0.5712v-6.4729c0.7615-0.4759 1.8086-0.8567 2.4749-0.8567 1.0471 0 1.9038 0.3808 1.9038 1.9038v5.521c0 0.5712-0.2855 0.5711-0.9519 0.5711h-0.4759v0.8568h4.9498v-0.8568h-0.5711c-0.6663 0-0.9519 1e-4 -0.9519-0.5711v-5.7113c0-2.1894-1.1422-3.2365-2.6653-3.2365-1.3326 0-2.3797 0.6663-3.7124 1.523v-1.3326c0-0.2856-0.1903-0.2856-0.2855-0.1904-0.7615 0.3808-2.0942 0.8567-2.9509 1.0471v0.6663" fill="#00803D" />
      <path d="m86.413 34.078 0.2856-0.3807c0-2.6653-1.9038-3.9028-3.8075-3.9028-1.8086 0-4.5691 1.523-4.5691 5.7114 0 1.7134 0.9519 4.9498 4.2835 4.9498 1.999 0 3.2364-1.3326 4.1883-2.5701l-0.4759-0.4759c-0.9519 0.9518-1.9038 1.4278-2.8557 1.4278-1.6182 0-2.9509-1.6182-3.1413-3.7124v-0.8567-0.2855h6.0921v0.0951zm-5.9969-0.9519c0.2856-1.4278 1.1423-2.3797 2.3798-2.3797 1.047 0 1.8086 0.7615 1.8086 1.9038 0 0.2856-0.1904 0.4759-0.476 0.4759h-3.7124z" fill="#00803D" />
      <path d="m164.85 11.899v0.5711h1.427v6.9488c0 1.4278 0.762 2.1894 2.19 2.1894 1.047 0 1.999-0.476 3.046-1.5231l-0.476-0.4759c-0.476 0.4759-0.952 0.5711-1.428 0.5711-0.286 0-1.237-0.0952-1.237-1.7134v-5.9017h3.141v-1.2375h-3.141v-2.1893h-0.476c-1.143 1.4278-2.19 2.1893-3.046 2.7605z" fill="#00803D" />
      <path d="m59.763 25.606c-0.2856 0.3808-1.1423 0.8567-1.4279 0.9519 0.1904 0.7616 0.0952 1.1423-0.3807 2.2846l-3.1413 7.6151c-0.1903 0.5711-0.4759 1.1423-0.6663 1.6182-0.5711 1.0471-0.8567 1.2375-2.0942 1.3327v0.7615h5.6162v-0.7615h-0.8567c-0.8567 0-1.2375-0.0952-1.2375-0.8567 0-0.5712 0.3808-1.523 0.5712-2.2846l0.4759-1.3326h5.2354l1.1423 2.7605c0.1904 0.3807 0.3808 0.9519 0.3808 1.1422 0 0.3808-0.3808 0.5712-1.1423 0.5712h-0.5712v0.7615h6.0922v-0.7615c-0.5712 0-1.2375 0-1.7134-0.476-0.2856-0.2855-0.7616-1.4278-1.0471-2.2845l-4.6643-11.042h-0.5711zm-0.6663 2.9509 2.2845 5.4258h-4.3787l2.0942-5.4258z" fill="#00803D" />
      <path d="m68.71 37.22c-0.1904 0.1904-0.2856 0.6663-0.2856 0.8567 0 0.9519 0.8567 1.6182 1.7134 1.9038-1.2374 0.2855-2.3797 1.1423-2.3797 2.5701 0 1.523 1.4279 2.5701 4.2835 2.5701 4.6643 0 5.997-2.9509 5.997-4.2835 0-1.6182-1.0471-2.475-3.1413-2.475h-3.046c-1.4279 0-1.4279-0.6663-1.4279-0.8566 0-0.476 0.2856-0.7616 0.5712-0.9519 0.3807 0.0951 0.8567 0.0952 1.1422 0.0952 2.7605 0 4.1883-1.8087 4.1883-3.5221 0-0.5711-0.1903-1.1422-0.4759-1.523 0.3807 0 2.0942 0.0952 2.0942 0.0952v-1.3326c-0.5712 0.0952-1.0471 0.0952-1.4279 0.0952-0.8567 0-1.4278-0.1904-1.9989-0.3808-0.6664-0.1904-1.4279-0.2856-1.999-0.2856-2.5701 0-4.2835 1.3327-4.2835 3.522 0 1.2375 0.6663 2.1894 1.9989 2.9509l-1.523 0.9519zm0.9519 4.9498c0-1.999 2.0942-1.9989 3.522-1.9989 1.999 0 2.9509 0.5711 2.9509 1.7134 0 1.9989-2.6653 2.3797-3.7124 2.3797-1.2375 0-2.7605-0.5712-2.7605-2.0942zm0.7615-9.1381c0-1.1423 0.6664-2.3798 1.9038-2.3798 1.4278 0 1.999 1.4279 1.999 2.7605 0 1.1423-0.5712 2.475-1.999 2.475-1.3326 0-1.9038-1.1423-1.9038-2.8557z" fill="#00803D" />
      <path d="m107.83 37.6c-0.857 0.6663-1.618 1.0471-2.665 1.0471-2.571 0-3.618-1.7134-3.618-4.1883 0-1.7134 1.238-3.522 2.761-3.522 1.142 0 1.523 0.4759 1.904 1.047 0.285 0.3808 0.476 0.6663 0.951 0.6663 0.572 0 0.952-0.5711 0.952-1.047 0-0.9519-1.237-1.6182-3.141-1.6182-3.046 0-5.3305 2.3797-5.3305 5.7113 0 2.7605 1.9995 4.7595 4.4735 4.7595 1.904 0 3.332-1.1423 4.284-2.2846l-0.571-0.5711z" fill="#00803D" />
      <path d="m113.16 39.695c-0.095 0.1904-0.571 1.3326-1.428 3.3316-0.286-0.1904-0.476-0.2856-0.857-0.2856-0.666 0-1.142 0.6664-1.142 1.2375 0 0.6663 0.476 1.1422 1.238 1.1422 0.666 0 1.142-0.3807 1.523-1.1422l4.949-11.708c0.381-0.9519 0.857-1.3326 1.714-1.3326v-0.8567h-3.998v0.8567c0.285 0 0.571 0 0.761 0.0952 0.286 0.0951 0.476 0.2855 0.476 0.7615 0 0.0951-0.095 0.3807-0.19 0.7615l-1.809 4.4739-1.808-4.5691c-0.191-0.476-0.381-0.8567-0.286-1.0471 0-0.1904 0.191-0.3807 0.476-0.3807 0.19 0 0.476-0.0952 0.762-0.0952v-0.7615h-4.76v0.8567h0.476c0.381 0.0951 0.666 0.2855 0.857 0.6663l3.046 7.9959z" fill="#00803D" />
      <path d="m47.862 23.703c0 13.041-10.566 23.607-23.607 23.607-13.041 0-23.607-10.566-23.607-23.607s10.566-23.607 23.607-23.607c13.041 0 23.607 10.566 23.607 23.607z" fill="#fff" />
      <path d="m18.639 46.643c1.8086 0.476 3.7124 0.6663 5.6162 0.6663 2.1893 0 4.2835-0.2856 6.2824-0.8567-1.8086-4.2835-5.9017-11.994-5.9017-11.994s-4.2835 7.9959-5.9969 12.184zm5.6162-46.547c-13.041 0-23.607 10.566-23.607 23.607 0 9.9949 6.1873 18.562 15.04 21.989 1.999-5.4258 4.3787-10.852 5.8066-13.707-0.0952-1.999-0.3808-3.7124-0.5712-5.1402-5.4257-1.3327-9.2333-5.0451-9.2333-5.0451l1.523-2.2845s5.2354 4.1883 11.518 4.1883 11.518-4.1883 11.518-4.1883l1.523 2.2845s-3.9027 3.7124-9.3285 5.0451c-0.1904 1.3326-0.476 3.1412-0.5712 5.045 1.3327 2.8557 3.7124 8.1862 5.7114 13.612 8.4718-3.6172 14.374-11.994 14.374-21.798-0.0952-13.041-10.661-23.607-23.702-23.607z" fill="#00803D" />
      <path d="m19.019 9.7097c-0.6663-1.0471-1.8086-1.7134-3.046-1.7134-2.0942 0-3.8076 1.6182-3.8076 3.7124v0.5711c1.1423 0.2856 3.0461 1.5231 3.1412 4.0932-1.047-1.3327-1.9989-1.8086-3.522-1.9038-2.2845-0.1904-4.1883 1.8086-4.1883 3.9979 0 0.8567 0.28556 1.6182 0.66632 2.2845-1.0471 0.9519-1.7134 2.2846-1.7134 3.7124-0.09519 2.8557 1.4278 4.8547 3.9028 5.3306 1.3327 0.1904 3.522-0.0952 4.8546-1.523-1.1422 2.4749-3.2364 3.4268-5.3305 3.4268-3.522 0-6.1873-3.046-6.1873-6.568 0-1.9038 0.85669-3.522 2.1893-4.6643-0.47594-0.8567-0.66632-1.8086-0.66632-2.7605 0.09519-2.7605 2.0942-5.045 4.7594-5.4258v-0.5711c0.0952-3.2365 2.6653-5.8066 5.9018-5.7114 1.047 0 1.9989 0.28559 2.8556 0.76153 0.9519-1.0471 2.3798-1.6182 3.9028-1.6182 2.6653 0 4.7595 1.999 5.2354 4.4739-1.0471-1.2375-2.5701-2.0942-4.2835-2.1894-1.9038-0.09519-3.6172 0.85673-4.6643 2.2846z" fill="#fff" />
      <path d="m27.493 19.99c0.0952-0.1904 0.0952-0.4759 0.0952-0.7615 0-1.2374-0.9519-2.1894-2.0942-2.1894s-2.0941 0.952-2.0941 2.1894c0 1.2375 0.9518 2.1893 2.0941 2.1893h0.1904c-0.1904 0.0952-0.3808 0.0952-0.4759 0.0952-1.9038 0.3808-3.6172-0.9519-3.998-2.9508-0.2856-1.999 0.9519-3.8076 2.7605-4.1883 1.8086-0.3808 3.6172 0.9519 3.9979 2.9508 0.1904 1.0471 0 1.999-0.4759 2.6653z" fill="#fff" />
      <path d="m42.058 24.75c0.6663-1.7134 0.5711-3.6172-0.3808-5.2354-1.0471-1.8086-2.8557-2.8557-4.7595-3.0461 0.2856-1.3326 0.476-2.5701-0.0951-3.9027-0.9519-2.2846-4.0932-3.3316-6.1873-2.5701 2.8556-2.0942 6.6632-1.6182 8.2814 1.3326 0.476 0.8567 0.7615 2.1894 0.7615 3.2364 1.5231 0.3808 2.8557 1.3327 3.7124 2.7605 1.4278 2.475 0.8567 5.6162-1.3326 7.4248z" fill="#fff" />
    </S.EnvAgency>
  )
}

const S = {
  EnvAgency: styled.svg`
    @media(min-width: 980px) {
      height: 60px;
    }
    @media(min-width: 650px) {
      height: 50px;
    }
  `
}

export default EnvAgency
