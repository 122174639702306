import React from 'react'
import styled from 'styled-components'

const Facebook = ({ height = 35, className }) => {
  return (
    <S.Facebook height={height} className={className} viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
      <path d="m40 20c0-11.047-8.9531-20-20-20-11.047 0-20 8.9531-20 20 0 9.9844 7.3125 18.258 16.875 19.758v-13.977h-5.0781v-5.7812h5.0781v-4.4062c0-5.0118 2.9844-7.7813 7.5547-7.7813 2.1875 0 4.4765 0.39062 4.4765 0.39062v4.9219h-2.5234c-2.4844 0-3.2578 1.543-3.2578 3.125v3.75h5.5469l-0.8867 5.7812h-4.6602v13.977c9.5625-1.5 16.875-9.7734 16.875-19.758z" fill="#1877F2" />
      <path d="m27.785 25.781 0.8867-5.7812h-5.5469v-3.75c0-1.582 0.7734-3.125 3.2578-3.125h2.5234v-4.9219s-2.289-0.39062-4.4765-0.39062c-4.5703 0-7.5547 2.7695-7.5547 7.7813v4.4062h-5.0781v5.7812h5.0781v13.977c1.0195 0.1602 2.0625 0.2422 3.125 0.2422s2.1055-0.082 3.125-0.2422v-13.977h4.6602z" fill="#fff" />
    </S.Facebook>
  )
}

const S = {
  Facebook: styled.svg`
    @media(min-width: 450px) {
      height: 45px;
    }
  `
}

export default Facebook
