import React from 'react'

const Phone = ({ height = 17.5, className }) => {
  return (
    <svg height={height} className={className} fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path d="m13.152 14.943 2.0782-2.0801c0.2539-0.2539 0.3965-0.5938 0.3965-0.9531 0-0.3594-0.1407-0.6993-0.3965-0.9532l-2.2364-2.2402c-0.2539-0.25391-0.5957-0.39649-0.955-0.39649-0.3614 0-0.6993 0.14063-0.9551 0.39649l-1.752 1.75c-0.92888-0.3987-1.7728-0.97148-2.4863-1.6875-0.7207-0.7168-1.2891-1.5547-1.6914-2.4844l1.752-1.75c0.25391-0.2539 0.39648-0.59375 0.39648-0.95312 6.6e-4 -0.17726-0.03407-0.35287-0.10215-0.51654-0.06808-0.16366-0.16815-0.31209-0.29433-0.43659l-2.2363-2.2422c-0.2539-0.25391-0.5957-0.39648-0.95508-0.39648-0.36132-1.5794e-8 -0.69922 0.14063-0.95507 0.39648l-2.0781 2.0781c-0.43359 0.43359-0.68164 1.0312-0.68164 1.6445-5.55e-9 0.12695 0.0097656 0.25 0.03125 0.375 0.42578 2.5859 1.8027 5.1523 3.8731 7.2246 2.0742 2.0704 4.6406 3.4454 7.2285 3.875 0.7344 0.1231 1.4902-0.123 2.0195-0.6503zm-1.7871-0.7344c-2.3027-0.3809-4.5996-1.6192-6.4648-3.4844-1.8652-1.8652-3.1035-4.1602-3.4844-6.4629-0.04883-0.28906 0.04882-0.58594 0.25781-0.79688l2.041-2.041 2.166 2.1641-2.3438 2.3398-0.01758 0.01758 0.15625 0.42188c0.4703 1.2792 1.2131 2.4408 2.177 3.4043 0.96387 0.96353 2.1257 1.7059 3.405 2.1758l0.42188 0.1562 2.3574-2.3594 2.166 2.1641-2.041 2.041c-0.2109 0.211-0.5078 0.3086-0.7969 0.2598z" fill="#073642" />
    </svg>
  )
}

export default Phone
