import React from 'react'
import styled from 'styled-components'

const GasSafe = ({ height = 58, className }) => {
  return (
    <S.GasSafe height={height} className={className} viewBox="0 0 74 80" xmlns="http://www.w3.org/2000/svg">
      <path d="m73.475 76.9c0 1.6305-1.3199 2.9246-2.9245 2.9246h-67.626c-1.6305 0-2.9245-1.3199-2.9245-2.9246v-73.682c0-1.6305 1.3199-2.9245 2.9245-2.9245h67.626c1.6305 0 2.9245 1.3199 2.9245 2.9245v73.682z" />
      <path d="m7.917 60.673c-0.33645 0.4141-0.56937 0.9576-0.56937 1.5529 0 1.3199 1.087 2.4069 2.4069 2.4069h53.832c1.3199 0 2.4069-1.087 2.4069-2.4069v-53.832c0-1.3199-1.087-2.4069-2.4069-2.4069-0.5694 0-1.1129 0.20704-1.5269 0.54348l-54.142 54.142z" fill="#FFED00" />
      <path d="m34.652 48.198c-0.207 0.0776-0.414 0.1294-0.647 0.1294-0.3364 0-0.647-0.0518-0.9058-0.1812s-0.4917-0.2847-0.6729-0.4917c-0.1812-0.2071-0.3365-0.44-0.4141-0.7247-0.1035-0.2847-0.1553-0.5694-0.1553-0.9058 0-0.3365 0.0518-0.647 0.1553-0.9058 0.1035-0.2847 0.2329-0.5177 0.4141-0.7247s0.4141-0.3623 0.6729-0.4917 0.5694-0.1812 0.9058-0.1812c0.233 0 0.44 0.0259 0.647 0.1035 0.2071 0.0777 0.4141 0.1553 0.5694 0.3106 0.1812 0.1294 0.3106 0.2847 0.4141 0.4917 0.1035 0.1812 0.1812 0.4141 0.2071 0.6729h-0.9318c-0.0517-0.2588-0.1811-0.4399-0.3364-0.5435-0.1553-0.1294-0.3623-0.1811-0.5953-0.1811-0.207 0-0.4141 0.0517-0.5435 0.1294-0.1552 0.0776-0.2846 0.207-0.3623 0.3364-0.1035 0.1294-0.1553 0.3106-0.207 0.4918-0.0518 0.1811-0.0518 0.3623-0.0518 0.5693 0 0.1812 0.0259 0.3624 0.0518 0.5435 0.0259 0.1812 0.1035 0.3365 0.207 0.4659s0.2071 0.2588 0.3623 0.3364c0.1553 0.0777 0.3365 0.1294 0.5435 0.1294 0.3106 0 0.5694-0.0776 0.7506-0.2329 0.1811-0.1553 0.2847-0.3882 0.3106-0.6988h-0.9835v-0.7246h1.8634v2.381h-0.6211l-0.1036-0.4917c-0.1552 0.1294-0.3364 0.2846-0.5435 0.3882z" />
      <path d="m38.796 43.824 1.6563 4.3997h-1.0093l-0.3365-0.9834h-1.6304l-0.3365 0.9834h-0.9835l1.6564-4.3997h0.9835zm0.0517 2.6916-0.5434-1.6305-0.5694 1.6305h1.1128z" />
      <path d="m41.46 47.137c0.0517 0.1035 0.1294 0.1811 0.207 0.2588 0.0777 0.0517 0.1812 0.1035 0.3106 0.1294s0.2329 0.0517 0.3623 0.0517c0.0777 0 0.1812 0 0.2847-0.0258 0.1036-0.0259 0.1812-0.0518 0.2847-0.0777 0.0777-0.0517 0.1553-0.1035 0.2071-0.1811 0.0517-0.0777 0.0776-0.1553 0.0776-0.2847s-0.0259-0.2071-0.1035-0.2847c-0.0776-0.0777-0.1812-0.1294-0.3106-0.1812-0.1294-0.0517-0.2588-0.1035-0.4141-0.1294s-0.3106-0.0776-0.4658-0.1294c-0.1553-0.0518-0.3365-0.1035-0.4918-0.1553-0.1553-0.0517-0.2847-0.1294-0.4141-0.2329s-0.2329-0.2071-0.3106-0.3623c-0.0776-0.1294-0.1035-0.3106-0.1035-0.5177 0-0.2329 0.0518-0.414 0.1553-0.5952 0.1035-0.1553 0.2329-0.3106 0.3882-0.4141s0.3365-0.1812 0.5176-0.2588c0.2071-0.0518 0.3883-0.0777 0.5953-0.0777 0.2329 0 0.44 0.0259 0.6729 0.0777 0.2071 0.0518 0.3883 0.1294 0.5694 0.2588 0.1553 0.1035 0.2847 0.2588 0.3882 0.44 0.1036 0.1811 0.1553 0.3882 0.1553 0.647h-0.9317c0-0.1294-0.0259-0.2329-0.0776-0.3365-0.0518-0.0776-0.1036-0.1552-0.1812-0.207-0.0777-0.0518-0.1553-0.0777-0.2588-0.1035-0.1036-0.0259-0.2071-0.0259-0.3365-0.0259-0.0776 0-0.1553 0-0.2329 0.0259-0.0777 0.0258-0.1553 0.0517-0.2071 0.0776-0.0517 0.0518-0.1035 0.1035-0.1553 0.1553-0.0517 0.0518-0.0517 0.1294-0.0517 0.2329 0 0.0777 0.0259 0.1553 0.0517 0.2071 0.0259 0.0517 0.1036 0.1035 0.2071 0.1553 0.1035 0.0517 0.2329 0.0776 0.3882 0.1294 0.1812 0.0517 0.3882 0.1035 0.6729 0.1811 0.0777 0.0259 0.2071 0.0518 0.3365 0.0777 0.1553 0.0517 0.2847 0.1035 0.44 0.207s0.2588 0.2329 0.3623 0.3882 0.1553 0.3624 0.1553 0.5953c0 0.207-0.0518 0.3882-0.1294 0.5694-0.0776 0.1811-0.2071 0.3105-0.3365 0.4399-0.1552 0.1294-0.3364 0.233-0.5694 0.2847-0.2329 0.0777-0.4917 0.1035-0.8023 0.1035-0.2329 0-0.4658-0.0258-0.6988-0.0776-0.2329-0.0518-0.44-0.1553-0.5953-0.2847-0.1811-0.1294-0.3105-0.2847-0.4141-0.4917-0.1035-0.2071-0.1552-0.44-0.1552-0.6988h0.9317c-0.0518 0.207-0.0259 0.3365 0.0259 0.44z" />
      <path d="m23.653 58.576c0.1553 0.2588 0.3364 0.4659 0.5693 0.6212 0.233 0.1552 0.4918 0.2847 0.8023 0.3623 0.3106 0.0776 0.5953 0.1035 0.9317 0.1035 0.233 0 0.4659-0.0259 0.7247-0.0776 0.2588-0.0518 0.4917-0.1294 0.6988-0.2588 0.207-0.1036 0.3882-0.2588 0.5176-0.4659 0.1294-0.1812 0.207-0.44 0.207-0.7246 0-0.4918-0.3364-0.8541-0.9834-1.087-0.647-0.233-1.5529-0.4918-2.7175-0.7247-0.4658-0.1035-0.9317-0.2329-1.3717-0.3882-0.4399-0.1553-0.854-0.3365-1.1905-0.5694-0.3364-0.2329-0.6211-0.5435-0.854-0.8799-0.2071-0.3624-0.3106-0.8023-0.3106-1.3199 0-0.7506 0.1553-1.3976 0.44-1.8635 0.2846-0.4917 0.6987-0.8799 1.1646-1.1646 0.4917-0.2847 1.0352-0.4917 1.6305-0.5952 0.6211-0.1036 1.2422-0.1812 1.8634-0.1812 0.647 0 1.2681 0.0518 1.8634 0.1812 0.5952 0.1294 1.1387 0.3364 1.6046 0.6211s0.8541 0.6729 1.1646 1.1646c0.3106 0.4918 0.4918 1.087 0.5694 1.8117h-3.0798c-0.0518-0.6212-0.2847-1.0611-0.6988-1.2682-0.44-0.2329-0.9317-0.3364-1.5011-0.3364-0.1811 0-0.3882 0-0.5952 0.0259-0.2071 0.0258-0.4141 0.0776-0.5953 0.1552-0.1811 0.0777-0.3364 0.1812-0.4399 0.3365-0.1294 0.1553-0.1812 0.3364-0.1812 0.5694 0 0.2847 0.1035 0.5176 0.3106 0.6987 0.207 0.1812 0.4917 0.3365 0.8281 0.44 0.3365 0.1035 0.7247 0.2071 1.1647 0.3106 0.4399 0.1035 0.8799 0.1812 1.3458 0.2847 0.4658 0.1035 0.9317 0.2329 1.3716 0.3882 0.44 0.1553 0.8541 0.3623 1.1906 0.5952 0.3623 0.2588 0.6211 0.5694 0.854 0.9317 0.2071 0.3624 0.3106 0.8282 0.3106 1.3717 0 0.7764-0.1553 1.4235-0.4659 1.9411-0.3105 0.5176-0.7246 0.9576-1.2164 1.2681-0.4917 0.3106-1.0869 0.5435-1.734 0.6729-0.647 0.1294-1.294 0.2071-1.9669 0.2071s-1.3458-0.0777-2.0187-0.2071c-0.647-0.1294-1.2423-0.3623-1.734-0.6729-0.5176-0.3105-0.9317-0.7505-1.2682-1.2681-0.3364-0.5176-0.5176-1.1905-0.5434-1.967h3.0798c0.0517 0.3883 0.1294 0.6988 0.2588 0.9576z" />
      <path d="m33.178 51.433c0.3364-0.4917 0.7505-0.9058 1.2681-1.2163 0.5177-0.3106 1.087-0.5177 1.734-0.6471 0.6471-0.1294 1.2941-0.207 1.9411-0.207 0.5953 0 1.1905 0.0518 1.8116 0.1294 0.6212 0.0776 1.1647 0.2588 1.6564 0.4917 0.4917 0.233 0.9058 0.5694 1.2423 1.0094 0.3106 0.44 0.4917 1.0093 0.4917 1.734v6.1337c0 0.5435 0.0259 1.0352 0.1035 1.527 0.0518 0.4917 0.1553 0.854 0.3106 1.087h-3.2868c-0.0518-0.1812-0.1036-0.3624-0.1553-0.5435-0.0259-0.1812-0.0518-0.3882-0.0777-0.5694-0.5176 0.5435-1.1387 0.9058-1.8375 1.1129-0.6988 0.207-1.4234 0.3105-2.1481 0.3105-0.5694 0-1.087-0.0776-1.5787-0.207s-0.9058-0.3623-1.2682-0.647c-0.3623-0.2847-0.647-0.6471-0.854-1.087-0.2071-0.44-0.3106-0.9576-0.3106-1.5787 0-0.6729 0.1294-1.2164 0.3623-1.6564 0.233-0.44 0.5435-0.7764 0.9059-1.0352 0.3623-0.2588 0.8023-0.4659 1.2681-0.5953 0.4917-0.1294 0.9576-0.2329 1.4493-0.3106 0.4918-0.0776 0.9576-0.1294 1.4494-0.1811 0.4658-0.0518 0.8799-0.1036 1.2681-0.2071 0.3623-0.1035 0.647-0.2329 0.8799-0.3882 0.2071-0.1811 0.3106-0.44 0.2847-0.7764 0-0.3623-0.0517-0.6211-0.1811-0.8282-0.1036-0.207-0.2588-0.3623-0.4659-0.4917-0.1811-0.1035-0.4141-0.1812-0.6729-0.2329-0.2588-0.0259-0.5176-0.0518-0.8023-0.0518-0.647 0-1.1387 0.1294-1.5011 0.4141-0.3623 0.2847-0.5693 0.7246-0.647 1.3717h-3.2351c0.0777-0.7506 0.2588-1.3717 0.5953-1.8635zm6.3925 4.5809c-0.207 0.0777-0.44 0.1294-0.6729 0.1812s-0.4917 0.0776-0.7505 0.1035-0.5176 0.0777-0.7764 0.1035c-0.233 0.0518-0.4918 0.1036-0.7247 0.1812-0.2329 0.0777-0.44 0.1812-0.6211 0.3106-0.1812 0.1294-0.3106 0.2847-0.4141 0.4917-0.1036 0.2071-0.1553 0.44-0.1553 0.7506 0 0.2846 0.0517 0.5434 0.1553 0.7246 0.1035 0.2071 0.2588 0.3623 0.4399 0.4659 0.1812 0.1035 0.3883 0.207 0.6471 0.2329 0.2329 0.0518 0.4917 0.0776 0.7505 0.0776 0.647 0 1.1388-0.1035 1.5011-0.3105 0.3623-0.2071 0.6211-0.4659 0.7764-0.7765 0.1553-0.2846 0.2588-0.5952 0.3106-0.9058 0.0259-0.3106 0.0517-0.5435 0.0517-0.7246v-1.2164c-0.1294 0.1294-0.3105 0.2329-0.5176 0.3105z" />
      <path d="m44.148 51.874v-2.174h1.9411v-0.9058c0-1.0611 0.3364-1.9152 0.9835-2.5881 0.647-0.6729 1.6304-1.0093 2.9504-1.0093 0.2846 0 0.5693 0 0.8799 0.0259 0.2847 0.0258 0.5694 0.0517 0.8541 0.0517v2.4069c-0.3882-0.0517-0.7765-0.0517-1.1905-0.0517-0.44 0-0.7506 0.1035-0.9576 0.3105-0.1812 0.2071-0.2847 0.5435-0.2847 1.0353v0.6987h2.2257v2.174h-2.2257v9.6277h-3.2351v-9.6277h-1.9411v0.0259z" />
      <path d="m55.98 58.654c0.4917 0.4658 1.1905 0.6987 2.0963 0.6987 0.647 0 1.2164-0.1553 1.6822-0.4917 0.4659-0.3365 0.7506-0.6729 0.88-1.0352h2.8469c-0.4659 1.4234-1.1647 2.4328-2.0964 3.028-0.9317 0.5953-2.0963 0.9058-3.4162 0.9058-0.9317 0-1.7599-0.1552-2.5105-0.4399-0.7505-0.2847-1.3716-0.7247-1.8892-1.2682-0.5177-0.5435-0.9059-1.1905-1.1906-1.9669-0.2846-0.7764-0.414-1.6046-0.414-2.5105 0-0.8799 0.1552-1.7081 0.4399-2.4586 0.2847-0.7506 0.6988-1.4235 1.2423-1.9669 0.5435-0.5435 1.1646-0.9835 1.9152-1.32 0.7246-0.3105 1.5528-0.4917 2.4586-0.4917 1.0094 0 1.8893 0.207 2.614 0.5694 0.7505 0.3882 1.3458 0.9058 1.8375 1.5528 0.4918 0.647 0.8282 1.3976 1.0352 2.2258 0.2071 0.8281 0.2847 1.7081 0.233 2.6139h-8.5148c0 1.1388 0.2847 1.8893 0.7506 2.3552zm3.675-6.2114c-0.3882-0.4141-0.9834-0.647-1.7599-0.647-0.5176 0-0.9576 0.0776-1.294 0.2588-0.3365 0.1811-0.6211 0.3882-0.8282 0.647-0.207 0.2588-0.3623 0.5435-0.44 0.8282-0.0776 0.2847-0.1294 0.5435-0.1552 0.7764h5.2796c-0.1553-0.8023-0.4141-1.4234-0.8023-1.8634z" />
      <path d="m60.379 44.989v0.207h-0.9059v2.4069h-0.2588v-2.4069h-0.9059v-0.207h2.0706z" />
      <path d="m60.896 44.989 0.9058 2.3034 0.9059-2.3034h0.3623v2.6139h-0.2588v-2.2775l-0.9058 2.2775h-0.233l-0.9058-2.2775v2.2775h-0.2588v-2.6139h0.3882z" />
      <path d="m34.705 67.013c0.2071 0 0.3882 0.0259 0.5435 0.1035 0.1553 0.0777 0.2847 0.1553 0.4141 0.2588 0.1035 0.1036 0.2071 0.233 0.2588 0.3882 0.0518 0.1553 0.0777 0.3106 0.0777 0.4659 0 0.2588-0.0518 0.4917-0.1553 0.6729-0.1035 0.1811-0.2847 0.3364-0.5435 0.44 0.1294 0.0258 0.207 0.0776 0.2847 0.1552 0.0776 0.0777 0.1294 0.1553 0.1811 0.233 0.0518 0.1035 0.0777 0.207 0.1036 0.3105 0.0258 0.1036 0.0259 0.233 0.0517 0.3365v0.2588c0 0.1035 0 0.1811 0.0259 0.2847 0 0.1035 0.0259 0.1811 0.0518 0.2847 0.0259 0.0776 0.0517 0.1552 0.1035 0.2329h-0.9576c-0.0518-0.1294-0.0777-0.3106-0.1035-0.4917 0-0.1812-0.0259-0.3883-0.0518-0.5435-0.0259-0.233-0.1035-0.3883-0.207-0.5177-0.1036-0.1035-0.2847-0.1552-0.5177-0.1552h-0.9576v1.734h-0.9835v-4.3997h2.3811v-0.0518zm-0.3365 1.9928c0.233 0 0.3883-0.0518 0.4918-0.1553s0.1553-0.2588 0.1553-0.4917c0-0.2071-0.0518-0.3623-0.1553-0.4659-0.1035-0.1035-0.2847-0.1552-0.4918-0.1552h-1.0611v1.2422h1.0611v0.0259z" fill="#FFED00" />
      <path d="m40.088 67.014v0.8023h-2.3292v0.9317h2.1222v0.7505h-2.1222v1.087h2.381v0.8023h-3.3386v-4.3997h3.2868v0.0259z" fill="#FFED00" />
      <path d="m43.401 71.388c-0.2071 0.0777-0.4141 0.1294-0.647 0.1294-0.3365 0-0.6471-0.0517-0.9059-0.1811s-0.4917-0.2847-0.6729-0.4918c-0.1811-0.207-0.3364-0.4399-0.414-0.7246-0.1036-0.2847-0.1553-0.5694-0.1553-0.9059 0-0.3364 0.0517-0.647 0.1553-0.9058 0.1035-0.2847 0.2329-0.5176 0.414-0.7246 0.1812-0.2071 0.4141-0.3624 0.6729-0.4918s0.5694-0.1811 0.9059-0.1811c0.2329 0 0.4399 0.0258 0.647 0.1035 0.207 0.0776 0.4141 0.1553 0.5694 0.3106 0.1811 0.1294 0.3105 0.2846 0.4141 0.4917 0.1035 0.1811 0.1811 0.4141 0.207 0.6729h-0.9317c-0.0518-0.2588-0.1812-0.44-0.3365-0.5435-0.1552-0.1294-0.3623-0.1812-0.5952-0.1812-0.2071 0-0.4141 0.0518-0.5435 0.1294-0.1553 0.0777-0.2847 0.2071-0.3623 0.3365-0.1036 0.1294-0.1553 0.3105-0.2071 0.4917-0.0517 0.1812-0.0518 0.3623-0.0518 0.5694 0 0.1811 0.0259 0.3623 0.0518 0.5435s0.1035 0.3364 0.2071 0.4658c0.1035 0.1294 0.207 0.2588 0.3623 0.3365 0.1553 0.0776 0.3364 0.1294 0.5435 0.1294 0.3105 0 0.5694-0.0777 0.7505-0.2329 0.1812-0.1553 0.2847-0.3882 0.3106-0.6988h-0.9835v-0.7247h1.8634v2.381h-0.6211l-0.1035-0.4917c-0.1294 0.1553-0.3365 0.3106-0.5435 0.3882z" fill="#FFED00" />
      <path d="m46.452 67.014v4.3997h-0.9834v-4.3997h0.9834z" fill="#FFED00" />
      <path d="m48.036 70.352c0.0518 0.1035 0.1294 0.1812 0.207 0.2588 0.0777 0.0518 0.1812 0.1035 0.3106 0.1294s0.2329 0.0518 0.3623 0.0518c0.0777 0 0.1812 0 0.2847-0.0259 0.1036-0.0259 0.1812-0.0518 0.2847-0.0777 0.0777-0.0517 0.1553-0.1035 0.2071-0.1811 0.0517-0.0777 0.0776-0.1553 0.0776-0.2847s-0.0259-0.2071-0.1035-0.2847c-0.0777-0.0776-0.1812-0.1294-0.3106-0.1812-0.1294-0.0517-0.2588-0.1035-0.4141-0.1294s-0.3105-0.0776-0.4658-0.1294c-0.1553-0.0517-0.3365-0.1035-0.4918-0.1553-0.1552-0.0517-0.2846-0.1294-0.4141-0.2329-0.1294-0.1035-0.2329-0.207-0.3105-0.3623-0.0777-0.1294-0.1035-0.3106-0.1035-0.5176 0-0.2329 0.0517-0.4141 0.1552-0.5953 0.1036-0.1553 0.233-0.3106 0.3883-0.4141 0.1552-0.1035 0.3364-0.1811 0.5176-0.2588 0.207-0.0517 0.3882-0.0776 0.5952-0.0776 0.2329 0 0.44 0.0259 0.6729 0.0776 0.2071 0.0518 0.3882 0.1294 0.5694 0.2588 0.1553 0.1035 0.2847 0.2588 0.3882 0.44s0.1553 0.3882 0.1553 0.647h-0.9576c0-0.1294-0.0259-0.2329-0.0776-0.3364-0.0518-0.0777-0.1036-0.1553-0.1812-0.2071-0.0776-0.0517-0.1553-0.0776-0.2588-0.1035s-0.2071-0.0259-0.3365-0.0259c-0.0776 0-0.1552 0-0.2329 0.0259-0.0776 0.0259-0.1553 0.0518-0.207 0.0776l-0.1553 0.1553c-0.0518 0.0518-0.0518 0.1294-0.0518 0.2329 0 0.0777 0.0259 0.1553 0.0518 0.2071s0.1035 0.1035 0.207 0.1553c0.1036 0.0517 0.233 0.0776 0.3882 0.1294 0.1812 0.0517 0.3882 0.1035 0.6729 0.1812 0.0777 0.0258 0.2071 0.0517 0.3365 0.0776 0.1553 0.0518 0.2847 0.1035 0.44 0.207 0.1552 0.1036 0.2588 0.233 0.3623 0.3882 0.1035 0.1553 0.1553 0.3624 0.1553 0.5953 0 0.207-0.0518 0.3882-0.1294 0.5694-0.0777 0.1811-0.2071 0.3106-0.3365 0.44-0.1553 0.1294-0.3364 0.2329-0.5694 0.2846-0.2329 0.0777-0.4917 0.1036-0.8023 0.1036-0.2329 0-0.4658-0.0259-0.6987-0.0777-0.233-0.0517-0.44-0.1553-0.5953-0.2847-0.1812-0.1294-0.3106-0.2846-0.4141-0.4917-0.1035-0.207-0.1553-0.44-0.1553-0.6988h0.9317c-0.0258 0.2071 0 0.3365 0.0518 0.44z" fill="#FFED00" />
      <path d="m50.957 67.842v-0.8282h3.6233v0.8023h-1.3199v3.5974h-0.9835v-3.5974h-1.3199v0.0259z" fill="#FFED00" />
      <path d="m58.389 67.014v0.8023h-2.3293v0.9317h2.1223v0.7505h-2.1223v1.087h2.3811v0.8023h-3.3645v-4.3997h3.3127v0.0259z" fill="#FFED00" />
      <path d="m61.465 67.014c0.2071 0 0.3882 0.0259 0.5435 0.1035 0.1553 0.0518 0.2847 0.1553 0.4141 0.2588 0.1035 0.1035 0.207 0.2329 0.2588 0.3882s0.0776 0.3106 0.0776 0.4659c0 0.2588-0.0517 0.4917-0.1552 0.6729-0.1036 0.1811-0.2847 0.3364-0.5435 0.4399 0.1294 0.0259 0.207 0.0777 0.2847 0.1553 0.0776 0.0777 0.1294 0.1553 0.1811 0.2329 0.0518 0.1036 0.0777 0.2071 0.1035 0.3106 0.0259 0.1035 0.0259 0.2329 0.0518 0.3365v0.2588c0 0.1035 0 0.1811 0.0259 0.2847 0 0.1035 0.0259 0.1811 0.0517 0.2846 0.0259 0.0777 0.0518 0.1553 0.1036 0.233h-0.9576c-0.0518-0.1294-0.0777-0.3106-0.1035-0.4918 0-0.1811-0.0259-0.3882-0.0518-0.5435-0.0259-0.2329-0.1035-0.3882-0.207-0.5176-0.1036-0.1035-0.2847-0.1553-0.5177-0.1553h-0.9576v1.7341h-0.9834v-4.3998h2.381v-0.0517zm-0.3623 1.9928c0.2329 0 0.3882-0.0518 0.4917-0.1553s0.1553-0.2588 0.1553-0.4917c0-0.2071-0.0518-0.3624-0.1553-0.4659s-0.2847-0.1553-0.4917-0.1553h-1.0611v1.2423h1.0611v0.0259z" fill="#FFED00" />
    </S.GasSafe>
  )
}

const S = {
  GasSafe: styled.svg`
    @media(min-width: 980px) {
      height: 74px;
    }
    @media(min-width: 650px) {
      height: 64px;
    }
  `
}

export default GasSafe
