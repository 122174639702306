import React from 'react'
import styled from 'styled-components'

const Ciphe = ({ height = 50, className }) => {
  return (
    <S.Ciphe height={height} className={className} viewBox="0 0 102 66" xmlns="http://www.w3.org/2000/svg">
      <path d="m61.588 47.327c-4.9094 8.7879-15.686 15.907-26.707 15.661-16.938-0.3682-30.561-13.256-30.561-30.561s13.575-30.88 30.88-30.88c4.2467 0 10.85 2.0129 15.097 4.2958 0.6382 0.27002 0.6137-0.49095 0.2455-0.68733-5.4495-3.4366-11.783-5.1549-17.969-5.1549-17.183 0.098189-32.476 14.409-32.476 32.795s13.992 32.182 32.525 33.016c11.414 0.5155 25.284-7.7324 29.898-18.46 0.1964-1.1047-0.6873-0.6137-0.9328-0.0246z" fill="#DA0E20" />
      <path d="m20.105 18.19h-9.9171v27.444h9.9171v-27.444z" fill="#373386" />
      <path d="m48.825 29.8c-0.1718-4.4676-2.0129-12.274-8.297-12.323-4.5658-0.0491-6.3086 2.9211-7.0696 3.903v-3.1666h-9.9171v36.919h9.9171v-12.789c1.8901 3.5839 5.2777 4.3203 6.8241 4.2712 8.7143-0.2454 8.7143-12.372 8.5425-16.815zm-12.47 8.1006c-2.9702 0-2.5284-5.8914-2.5284-5.8914s-0.3191-6.1613 2.4793-6.1613c2.5038 0 2.4056 5.9404 2.4056 5.9404s0.3682 6.1368-2.3565 6.1123z" fill="#373386" />
      <path d="m75.337 23.786c-0.1473-5.8914-5.1549-6.235-6.5786-6.2105-4.3695 0.0491-6.4314 3.0193-7.1433 3.8048v-18.533h-9.8925v42.982h9.868v-17.109c0.3436-0.761 0.5645-1.5465 1.8901-1.5956 1.7674-0.0982 1.8656 1.8656 1.8656 1.8656l0.0736 16.766 9.868-0.0737c0.0246-0.0245 0.0982-19.736 0.0491-21.896z" fill="#373386" />
      <path d="m90.037 39.251c2.7739 0.2454 2.9702-3.0194 2.9702-3.0194l8.542-0.049s-0.957 11.611-12.96 10.457c-11.783-1.1537-11.169-13.256-11.169-14.188 0-2.7739-0.3191-15.048 13.059-15.023 13.427 0.0491 11.414 15.784 11.414 15.784l-14.655-0.0736c0-0.0246-0.9328 5.7931 2.7984 6.1123zm-2.7247-10.825 5.8668 0.0246s0.5155-4.7131-2.9457-4.7131c-2.872 0.0245-2.9211 4.6885-2.9211 4.6885z" fill="#373386" />
    </S.Ciphe>
  )
}

const S = {
  Ciphe: styled.svg`
    @media(min-width: 980px) {
      height: 70px;
    }
    @media(min-width: 650px) {
      height: 60px;
    }
  `
}

export default Ciphe
