export const TempSanityWork = {
  edges: [
    {
      node: {
        title: 'Boiler Installations',
        slug: { current: 'boiler-installations' },
      }
    },
    {
      node: {
        title: 'Heating & Hot Water',
        slug: { current: 'heating-and-hot-water' },
      }
    },
    {
      node: {
        title: 'Gas',
        slug: { current: 'gas' },
      }
    },
    {
      node: {
        title: 'Plumbing',
        slug: { current: 'plumbing' },
      }
    },
    {
      node: {
        title: 'Power Flushing',
        slug: { current: 'power-flushing' },
      }
    },
    {
      node: {
        title: 'Bespoke Bathrooms',
        slug: { current: 'bespoke-bathrooms' },
      }
    },
    {
      node: {
        title: 'Servicing & Maintenance',
        slug: { current: 'servicing' },
      }
    },
    {
      node: {
        title: 'Property Maintenance',
        slug: { current: 'property-maintenance' },
      }
    },
  ]
}