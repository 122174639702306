import React from 'react'

const SWDropletQuotes = ({ width = 60, className }) => {
  return (
    <svg width={width} className={className} viewBox="0 0 229 296" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M114.296 0C104.618 25.1644 83.3246 63.879 34.9314 112.272C1.05622 147.115 -8.6224 188.733 7.83127 228.416C25.2528 269.066 66.8709 295.198 114.296 295.198C161.721 295.198 203.34 269.066 220.761 228.416C237.215 189.701 227.536 147.115 193.661 112.272C145.268 63.879 123.975 24.1966 114.296 0Z" fill="url(#paint0_linear)" />
      <path d="M100.2 148C100.2 148 91.7996 159 90.9996 167.1C91.5996 167.3 92.1996 167.5 92.7996 167.7C103.1 170.4 110.7 179.7 110.7 190.8C110.7 202.5 102.2 212.3 91.0996 214.3C89.5996 214.6 88.0996 214.8 86.4996 214.8C69.6996 214.8 62.9996 200.7 62.9996 190.8C62.8996 166.4 90.0996 149.8 100.2 148ZM117.3 190.8C117.3 200.7 124 214.8 140.8 214.8C142.4 214.8 143.9 214.6 145.4 214.3C156.6 212.3 165.1 202.5 165.1 190.8C165.1 179.7 157.5 170.4 147.3 167.7C146.7 167.5 146.1 167.3 145.5 167.1C146.3 159 154.7 148 154.7 148C144.5 149.8 117.3 166.4 117.3 190.8Z" fill="white" />
      <defs>
        <linearGradient id="paint0_linear" x1="33.8503" y1="14.4231" x2="191.148" y2="358.147" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B2D9F4" />
          <stop offset="0.8714" stopColor="#00ABE7" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SWDropletQuotes
